/**
 * Transfers the search params from the current location path to a newly built path
 */
export const buildPathWithParams = (pathname: string) => {
  const searchParams = new URLSearchParams(location.search)
  return `${pathname}?${searchParams.toString()}`
}

export const getReturnToPath = (fallback = '/') => {
  const searchParams = new URLSearchParams(location.search)
  let returnTo = searchParams.get('returnPath') ?? fallback

  searchParams.delete('returnPath')
  const remainingSearchParams = searchParams.toString()
  let validReturnTo

  // only allow returning to internal pages. e.g. /projects
  try {
    // if returnTo is a relative path, this will throw an error
    new URL(returnTo)
    // if no error, returnTo is a valid URL and NOT an internal page
    validReturnTo = fallback
  } catch (_) {
    validReturnTo = returnTo
  }

  return validReturnTo + (remainingSearchParams ? `?${remainingSearchParams}` : '')
}
