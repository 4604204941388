'use client'
import { useRef, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Loader2, Loader2Icon } from 'lucide-react'
import { TextInput } from '@tremor/react'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { useRouter } from 'next/navigation';
import Link from 'next/link';
import { getReturnToPath } from '@/lib/gotrue'
import { useQueryClient } from '@tanstack/react-query'
import { createClient } from '@/utils/supabase/client';

import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from '@/types/jwtPayload';
export const buildPathWithParams = (pathname: string) => {
  const searchParams = new URLSearchParams(location.search)
  return `${pathname}?${searchParams.toString()}`
}

const SigInForm = () => {
  const supabase = createClient()
  const queryClient = useQueryClient()
  const [showConditions, setShowConditions] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const [isSigningIn, setIsSigningIn] = useState(false)
  const { toast } = useToast()
  const router = useRouter()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
    },
    validationSchema: yup.object({
      email: yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: yup.string().required(),
    }),
    onSubmit: async (values, helpers) => {
      setIsSigningIn(true)
      const { data, error } = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password,
      })
      if (error) {

        if (error.message.toLowerCase() === 'email not confirmed') {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: `Account has not been verified, please check the link sent to your email`,
          })
        } else {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: `Failed to sign up: ${error.message}`,
          })
        }

        // check if user is a business account from ther session
        let isBusinessAccount = false
        const session = data.session as any
        if (session) {
          const jwt = jwtDecode<JwtPayload>(session!.access_token!)
          console.log('jwt session', jwt);

          isBusinessAccount = jwt.business_account_role
          // if not a business account, redirect to the home page
          if (isBusinessAccount) {
            await supabase.auth.signOut();
            toast({
              variant: 'destructive',
              title: 'Error',
              description: `You are not authorized to access this page`,
            })
          }
        }
        helpers.setSubmitting(false)
        setIsSigningIn(false)
      } else {
        const { data } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()
        if (data) {
          if (data.currentLevel !== data.nextLevel) {
            const url = buildPathWithParams('/sign-in-mfa')
            router.replace(url)
            return
          }
        }
        await queryClient.resetQueries()

        // call /api/revalidate get and pass the returnpath as query params
        fetch('/api/revalidate?path=' + getReturnToPath(), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-pathname': getReturnToPath(),
          },
        })
        router.push(getReturnToPath())
      }
    },
  });

  return (
    <div className="relative">
      <div
        className={`absolute top-0 w-full delay-300 duration-500 ${isSubmitted ? 'opacity-100' : 'opacity-0'
          }`}
      >
        <Alert className="w-full" title="Check your email to confirm">
          <AlertTitle>  Check your email to confirm</AlertTitle>
          <AlertDescription>
            {`You've successfully signed up. Please check your email to confirm your account before
            signing in to the Relistex`}
          </AlertDescription>
        </Alert>
      </div>
      <form noValidate onSubmit={formik.handleSubmit} className={`w-full overflow-y-hidden py-1 transition-all duration-500 ${isSubmitted ? 'pointer-events-none max-h-[100px] opacity-0' : 'max-h-[1000px] opacity-100'
        }`}>
        <div className="flex flex-col gap-4">
          <div className="grid gap-2">
            <div className="flex space-x-1">
              <Label htmlFor="subject">Email</Label>
            </div>
            <TextInput
              id="email"
              name="email"
              type="email"
              errorMessage={formik.touched.password && formik.errors.password || ''}
              error={Boolean(formik.touched.password && formik.errors.password)}
              placeholder="you@example.com"
              disabled={isSigningIn}
              defaultValue={formik.values.email}
              onValueChange={(value) => {
                formik.setFieldValue('email', value)
              }}
              autoComplete="email"
            />
          </div>

          <div className="grid gap-2">
            <div className="flex justify-between space-x-1">
              <Label htmlFor="subject">Password</Label>


            </div>

            <TextInput
              id="password"
              name="password"
              type={'password'}
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              disabled={isSigningIn}
              errorMessage={formik.touched.password && formik.errors.password || ''}
              error={Boolean(formik.touched.password && formik.errors.password)}
              defaultValue={formik.values.password}
              onValueChange={(value) => {
                formik.setFieldValue('password', value)
              }}
              autoComplete="new-password"
              onFocus={() => setShowConditions(true)}
            />
          </div>
          <Link
            href="/forgot-password?returnPath=/login/individual"
            className='text-primary-500 ml-auto cursor-pointer text-xs hover:underline'
          >
            Forgot Password?
          </Link>
          <Button
            type='submit'
            size="sm"
            disabled={formik.values.password.length === 0 || isSigningIn}
          >
            {isSigningIn && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}  Sign in
          </Button>
        </div>

      </form>
    </div>
  )
}

export default SigInForm
